<template>
  <a
    :href="href ?? to"
    :title="name ? name : undefined"
    :aria-label="name ? name : undefined"
    :target="target ? target : undefined"
  >
    <slot />
  </a>
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  export default defineNuxtComponent({
    name: 'TheLinkUi',

    props: {
      to: {
        type: String as PropType<string>,
        default: null,
      },
      href: {
        type: String as PropType<string>,
        default: null,
      },
      name: {
        type: String as PropType<string>,
        default: null,
      },
      target: {
        type: String as PropType<string>,
        default: null,
      },
    },
  });
</script>
